module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"AWS MachineLearing Dev","short_name":"AWS MachineLearing Dev","description":"All Thing Machine Learning with focus on AWS Platform","start_url":"/","background_color":"white","theme_color":"#5183f5","display":"minimal-ui","icon":"static/logo.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"8759c0af44adef52fba7941df36a6165"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-171801670-1"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"classPrefix":"language-","inlineCodeMarker":null,"aliases":{},"showLineNumbers":true,"noInlineHighlight":true,"prompt":{"user":"root","host":"localhost","global":true}},
    }]
